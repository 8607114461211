import React from 'react';
import LayoutInfoPage from '../../components/layoutInfoPage';
import './style.css';

const AboutUs = props => {
  return (
    <div className="about-us_container">
      <LayoutInfoPage title="Qui som?">
        <div className="about-us_content">
          <p>
            JCR som una empresa dedicada a la fabricació d’articles per a la preservació i la
            conservació del patrimoni cultural des del 1991.
          </p>
          <p>
            Oferim una extensa gamma de productes de qualitat per a la preservació i la conservació
            per a l’arxiu de materials textuals, gràfics, i fons fotogràfics com: caixes,
            arxivadors, carpetes, sobres, fundes...
          </p>
          <p>
            A JCR volem arribar a totes les institucions, els professionals i particulars
            interessats en la preservació i la conservació del patrimoni cultural.
          </p>
          <p>
            JCR amb uns quants anys d’experiència en el sector en subministre, disseny i fabricació
            de materials permanents per a la preservació i conservació del patrimoni cultural,
            oferim als nostres clients els productes que els sigui més adients per a la conservació
            incloent si és necessari la fabricació a mida dels materials. Les nostres primeres
            matèries estan recolzades per certificats i analitzades pel nostre servei de verificació
            de qualitat, per el laboratori General d’Assaig i Investigacions i pel laboratori
            estranger Image Permanence Institute.
          </p>
        </div>
        <div className="about-us_contact">
          <p>Ctra. de Cornellà, 128</p>
          <p>08950 Esplugues de Llobregat - BARCELONA</p>
          <p>Tel/Fax (34) 93 473 86 14</p>
          <p>jcr@jcr1.com</p>
        </div>
      </LayoutInfoPage>
    </div>
  );
};

export default AboutUs;
