import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const LayoutInfoPage = ({ children, title }) => {
  return (
    <div className="container-layout-info">
      <p className="title-layout-info">{title}</p>        
      <div className="content-layout-info">{children}</div>
    </div>
  );
};

LayoutInfoPage.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default LayoutInfoPage;
